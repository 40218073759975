import {useCallback} from 'react';
import {useWindowDimensions} from 'react-native';

/** 动态更新宽高,基于useWindowDimensions封装,限制了宽度最大为500 */
export const useResponsiveDimensions = () => {
  const {width, height} = useWindowDimensions();

  const maxWidth = 500;

  const responsiveWidth = width > maxWidth ? maxWidth : width;

  return {
    width: responsiveWidth,
    height,
  };
};

export function useScreenSize() {
  const {width: screenWidth, height: screenHeight} = useResponsiveDimensions();
  const designWidth = 375;
  // 根据实际宽度与设计宽度比例变换值
  // Change the value according to the actual width and design width ratio
  const calcActualSize = useCallback(
    (size: number) => {
      return (size * screenWidth) / designWidth;
    },
    [screenWidth],
  );
  return {
    screenWidth,
    screenHeight,
    designWidth,
    calcActualSize,
  };
}
