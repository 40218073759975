import {useEffect, useRef} from 'react';
import SockJS from 'sockjs-client';
import {envConfig} from '@/utils';
import {useLogin} from '@/utils/state-hooks';
import {getUUID} from '@/utils';
import {packageId} from '@/config';
import {useDebounceFn} from 'ahooks';
interface StompItem {
  topic: string;
  onSubscribe: (messageList: any[]) => void;
  onlyUser?: boolean;
}

export const GAME_TOPIC = 'GAME';

function getMessageKey(topic: string, onlyUser: boolean) {
  return `${topic}-${onlyUser ? 'user' : 'common'}`;
}

export function useStompClient(stompItems: StompItem[]) {
  const socket = useRef<WebSocket>();
  const stompClient = useRef<any>();
  const reconnectTimer = useRef<NodeJS.Timeout>();

  const login = useLogin();

  const messageMap = useRef<Record<string, any[]>>({});

  const {run: runSubscribe} = useDebounceFn(
    (
      originSubscribe: (message: any[]) => void,
      topic: string,
      onlyUser: boolean,
    ) => {
      const key = getMessageKey(topic, onlyUser);
      const list = messageMap.current[key];
      if (list && list.length) {
        originSubscribe(messageMap.current[key].reverse());
        messageMap.current[key] = [];
      }
    },
    {wait: 1000},
  );

  useEffect(() => {
    if (!stompItems.length) {
      return;
    }

    const disconnectStomp = () => {
      try {
        stompClient.current?.disconnect();
        stompClient.current = undefined;
      } catch (e) {
        console.log('disconnect stomp error');
        socket.current?.close();
        stompClient.current = undefined;
        socket.current = undefined;
      } finally {
        clearTimeout(reconnectTimer.current);
        reconnectTimer.current = undefined;
      }
    };

    const connectStomp = () => {
      socket.current = new SockJS(`${envConfig.baseUrl}push/ws`);

      stompClient.current = (Stomp as any).over(socket.current);
      const userStr = localStorage.getItem('user');
      let userId = '';
      if (!userStr) {
        userId = localStorage.getItem('visitor') || getUUID();
      } else {
        try {
          userId = JSON.parse(userStr).userId;
        } catch (e) {
          userId = getUUID();
        }
      }
      stompClient.current.connect(
        {userId},
        () => {
          stompItems.map(item => {
            stompClient.current?.subscribe(
              `/package/${packageId}${item.onlyUser ? `/${userId}` : ''}/${
                item.topic
              }`,
              (message: any) => {
                const key = getMessageKey(item.topic, !!item.onlyUser);
                if (!messageMap.current[key]) {
                  messageMap.current[key] = [];
                }
                messageMap.current[key].push(message.body);
                runSubscribe(item.onSubscribe, item.topic, item.onlyUser);
              },
            );
          });
        },
        () => {
          if (stompClient.current) {
            disconnectStomp();
            reconnectTimer.current = setTimeout(() => {
              connectStomp();
            }, 1000);
          }
        },
      );
    };

    connectStomp();

    return () => {
      disconnectStomp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, stompItems]);
}
