import React from 'react';
import {Button, Modal} from 'antd-mobile';
import './gameModal.scss';
import i18n from '@/i18n';

export const getGameModal = (toGameFree: () => void, toGame: () => void) => {
  const modal = Modal.show({
    closeOnMaskClick: true,
    showCloseButton: true,
    bodyStyle: {
      width: '20.4375rem',
      borderRadius: '1.25rem',
      backgroundColor: 'var(--card)',
      padding: '1.5rem',
    },
    content: (
      <div className="flex flex-col gap-6 mt-8 items-center">
        <div className="text-t1 text-xl font-bold">
          {i18n.t('gameModal.tip.choose')}
        </div>
        <div className="text-t2 text-sm font-normal tracking-tighter flex flex-col items-center">
          <p>{i18n.t('gameModal.tip.desc1')}</p>
          <p>{i18n.t('gameModal.tip.desc2')}</p>
          <p>{i18n.t('gameModal.tip.desc3')}</p>
        </div>
        <div className="flex flex-row w-full gap-3 py-3">
          <Button
            onClick={() => {
              toGame();
              modal.close();
            }}
            className="flex-1 h-10 border border-[var(--T2)] bg-[var(--button-small)] text-t2 text-sm font-bold">
            {i18n.t('gameModal.label.start')}
          </Button>
          <Button
            onClick={() => {
              toGameFree();
              modal.close();
            }}
            className="flex-1 h-10 bg-c1 text-t1 text-sm font-bold">
            {i18n.t('gameModal.label.free')}
          </Button>
        </div>
      </div>
    ),
  });
};
